import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { getPasswordFromLocalStorage, removePasswordFromLocalStorage, savePasswordInLocalStorage } from 'api/utils';
import * as requests from './requests';

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  loading: boolean;
  login: (password: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [loading, setLoading] = useState(false);

  function checkIfIsAuthenticated(): boolean {
    const password = getPasswordFromLocalStorage();
    return !!password;
  }

  const isAuthenticated = checkIfIsAuthenticated();

  const login = useCallback(async (password: string): Promise<void> => {
    setLoading(true);

    try {
      const response = await requests.login(password);
      if (response.status === 200) savePasswordInLocalStorage(password);
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.response?.status === 401) {
        removePasswordFromLocalStorage();
        throw new Error('Invalid credentials');
      } else {
        // eslint-disable-next-line no-console
        console.error('Login failed: ' + error.message);
        throw new Error('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return useMemo(
    () => <AuthContext.Provider value={{ isAuthenticated, login, loading }}>{children}</AuthContext.Provider>,
    [isAuthenticated, login, loading, children]
  );
};

export { AuthContext, AuthProvider };
