import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { getPasswordFromLocalStorage } from 'api/utils';
import { paths } from 'app/routes/paths.const';
import { AuthContext } from 'auth/AuthContext';
import Loader from 'theme/loader';
import styles from './AuthChecker.module.scss';

const AuthChecker = (): JSX.Element => {
  const navigate = useNavigate();
  const { loading, login } = useContext(AuthContext);

  useEffect(() => {
    const password = getPasswordFromLocalStorage();

    if (password) login(password).catch(() => navigate(paths.loginError));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <div className={styles.loadingArea}>
          <Loader />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default AuthChecker;
