import React from 'react';
import clsx from 'clsx';
import Icon, { icons } from 'theme/icon';

interface LogoProps {
  className?: string;
  size?: keyof typeof sizes;
}

export const sizes = {
  small: { width: 142, height: 18 },
  medium: { width: 178, height: 24 },
};

const Logo = ({ size = 'medium', className }: LogoProps): JSX.Element => {
  return <Icon icon={icons.logo} className={clsx(className)} size={sizes[size]} />;
};

export default Logo;
