import { paths } from 'app/routes/paths.const';
import { FilterTable } from './types';

export const FILTERS: FilterTable[] = [
  {
    name: 'ClearAir Pro',
    buttonLink: paths.contact,
    lampType: '9W',
    voltage: '60V',
    powerUVC: '2,5',
    batteryTime: '8h',
    mountingMethod: 'plecak',
    filtrationSystem: 'rewolwer',
    breath: 'normalny',
    dimension: '',
    ozoneRelease: '0',
    laboratoryCertificate: true,
    app: true,
    case: 'kompozyt',
    additionalBatteries: false,
    charging: 'wymienny akumulator',
    quartzGlassType: 'JGS 1',
    replaceableBattery: true,
    batteryChargingIndicator: true,
    guarantee: '24 msc',
    price: '3000 PLN',
  },
  {
    name: 'ClearAir Medical',
    buttonLink: paths.contact,
    lampType: '18W',
    voltage: '58V',
    powerUVC: '5,5',
    batteryTime: '5h',
    mountingMethod: 'pasek',
    filtrationSystem: 'kanał prostokątny',
    breath: 'podwyższony przez wysiłek',
    dimension: '',
    ozoneRelease: '0',
    laboratoryCertificate: false,
    app: true,
    case: 'plastik',
    additionalBatteries: true,
    charging: 'stacja dokująca',
    quartzGlassType: 'JGS 1',
    replaceableBattery: true,
    batteryChargingIndicator: true,
    guarantee: '36 msc',
    price: '7000 PLN',
  },
  {
    name: 'ClearAir Army',
    buttonLink: paths.contact,
    lampType: '18W',
    voltage: '58V',
    powerUVC: '5,5',
    batteryTime: '10h',
    mountingMethod: 'pasek',
    filtrationSystem: 'kanał prostokątny',
    breath: 'podwyższony przez wysiłek',
    dimension: '',
    ozoneRelease: '0',
    laboratoryCertificate: false,
    app: true,
    case: 'zbrojony, kuloodporny',
    additionalBatteries: true,
    charging: 'stacja dokująca',
    quartzGlassType: 'JGS 1',
    replaceableBattery: true,
    batteryChargingIndicator: true,
    guarantee: '72 msc',
    price: '10000 PLN',
  },
];
