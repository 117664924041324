import { LOCAL_STORAGE_AUTH_NAME } from 'auth/constants';

export const getPasswordFromLocalStorage = (): string | null => {
  return localStorage.getItem(LOCAL_STORAGE_AUTH_NAME.PASSWORD);
};

export const savePasswordInLocalStorage = (password: string): void => {
  localStorage.setItem(LOCAL_STORAGE_AUTH_NAME.PASSWORD, password);
};

export function removePasswordFromLocalStorage(): void {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_NAME.PASSWORD);
}
