import { IMAGES } from 'theme/assets/png/images';
import Footer from 'theme/footer/Footer';
import Hero from 'theme/hero';
import Navbar from 'theme/navbar';
import { useTitle } from 'utils/hooks';
import FilterAdvantages from './filterAdvantages';
import FilterComparison from './filterComparison';
import FilterInformation from './filterInformation';

const Home = (): JSX.Element => {
  useTitle('Home');

  return (
    <main>
      <Navbar />
      <Hero title="Home" backgroundHeroImage={IMAGES.toyCarAccident} />
      <FilterInformation />
      <FilterAdvantages />
      <FilterComparison />
      <Footer />
    </main>
  );
};

export default Home;
