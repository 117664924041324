import { IMAGES } from 'theme/assets/png/images';
import styles from './Advantages.module.scss';

const advantages = [
  {
    descriptionFirstPart: 'Zastosowanie szkla kwarcowego JGS 1 ',
    descriptionBold: 'zapewnia przenikanie ponad 90% światła UVC',
    descriptionSecondPart:
      ', zapewwniajac dwukrotnie wyzsza moc sterylizujkaca od innych szkieł kawarcowych dostepnych na rynku.',
    image: IMAGES.chart1,
  },
  {
    descriptionFirstPart:
      'Konstrukcja została zamknięta w aluminiowym korpusie ze specjalną powłoką odbijającą ponad 85% światła uvc co powoduje ',
    descriptionBold: 'kilkukrotne odbicie światła zwiększąjc tym samym moc.',
    image: IMAGES.chart2,
  },
];

const Advantages = (): JSX.Element => {
  return (
    <>
      {advantages.map(({ image, descriptionFirstPart, descriptionBold, descriptionSecondPart }, index) => (
        <section className={styles.advantage} key={index}>
          <img src={image} alt="light transmittance chart" className={styles.image} />
          <div className={styles.information}>
            <p>
              {descriptionFirstPart}
              <strong>{descriptionBold}</strong>
              {descriptionSecondPart}
            </p>
          </div>
        </section>
      ))}
    </>
  );
};

export default Advantages;
