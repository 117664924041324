import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as Arrow } from 'theme/assets/svg/arrow.svg';
import { ReactComponent as Check } from 'theme/assets/svg/check.svg';
import { ReactComponent as Close } from 'theme/assets/svg/close.svg';
import { ReactComponent as Dropdown } from 'theme/assets/svg/dropdown.svg';
import { ReactComponent as ExclamationMark } from 'theme/assets/svg/exclamation-mark.svg';
import { ReactComponent as LogoIcon } from 'theme/assets/svg/logo.svg';
import { ReactComponent as Rectangle } from 'theme/assets/svg/rectangle.svg';
import { ReactComponent as Reply } from 'theme/assets/svg/reply.svg';
import { ReactComponent as Sad } from 'theme/assets/svg/sad.svg';
import { ReactComponent as Tick } from 'theme/assets/svg/tick.svg';

export const icons = {
  arrow: Arrow,
  check: Check,
  close: Close,
  dropdown: Dropdown,
  exclamationMark: ExclamationMark,
  logo: LogoIcon,
  reply: Reply,
  rectangle: Rectangle,
  sad: Sad,
  tick: Tick,
};

export const iconSize = {
  small: {
    width: 20,
    height: 20,
  },
  medium: {
    width: 26,
    height: 26,
  },
  large: {
    width: 36,
    height: 36,
  },
};

interface IconProps {
  ariaLabel?: string;
  className?: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  size?: { width: number; height: number };
}

const Icon = ({ ariaLabel, className, icon, onClick, size = iconSize.small }: IconProps): JSX.Element => {
  const Component = icon;
  return (
    <Component
      height={size.height}
      className={className}
      width={size.width}
      aria-label={ariaLabel}
      onClick={() => onClick?.()}
    />
  );
};

export default Icon;
