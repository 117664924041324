import { useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext } from 'auth/AuthContext';
import Login from 'auth/login';
import env from 'domain/env';
import Home from 'home';
import ScrollToTop from './ScrollToTop';
import AuthChecker from './authChecker';
import ErrorBoundary from './errorBoundary';
import { paths } from './paths.const';

const Router = (): JSX.Element => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <BrowserRouter basename={env.publicUrl}>
      <ErrorBoundary>
        <ScrollToTop />
        <Routes>
          {!isAuthenticated ? (
            <>
              <Route element={<Login />} path={paths.login} />
              <Route path="*" element={<Navigate to={paths.login} replace />} />
            </>
          ) : (
            <Route element={<AuthChecker />}>
              <Route path={paths.home} element={<Home />} />
              <Route path="*" element={<Navigate to={paths.home} replace />} />
            </Route>
          )}
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default Router;
