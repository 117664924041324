import BgLogin from './bg-login.png';
import Chart1 from './chart-1.png';
import Chart2 from './chart-2.png';
import ManWithMedicalMask from './man-in-a-medical-mask.png';
import RespiratorMask from './respirator-mask.png';
import ToyCarAccident from './toy-car-accident.png';
import Toy from './toy.png';
import Virus from './virus.png';
import Wavelength from './wavelength.png';

export const IMAGES = {
  bgLogin: BgLogin,
  chart1: Chart1,
  chart2: Chart2,
  manWithMedicalMask: ManWithMedicalMask,
  respiratorMask: RespiratorMask,
  wavelength: Wavelength,
  toyCarAccident: ToyCarAccident,
  toy: Toy,
  virus: Virus,
};
