import { useContext, useEffect, useRef, useState, FormEvent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { paths } from 'app/routes/paths.const';
import { AuthContext } from 'auth/AuthContext';
import Icon, { icons } from 'theme/icon';
import Input from 'theme/input';
import Loader from 'theme/loader';
import Logo from 'theme/logo';
import { useFormValidation, useTitle } from 'utils/hooks';
import styles from './Login.module.scss';

const INVALID_PASSWORD_ERROR_MESSAGE = 'Invalid password';

function Login(): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const hasRedirectedFromMainWithError = searchParams.get('error');

  const { loading, login } = useContext(AuthContext);

  const inputRef = useRef<HTMLInputElement>(null);

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useTitle('Login');

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError('');
        navigate(paths.login);
      }, 5000);

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (hasRedirectedFromMainWithError) setError(INVALID_PASSWORD_ERROR_MESSAGE);
  }, [hasRedirectedFromMainWithError]);

  const { areThereAnyValidationErrors, clearValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(['passwordRequired']);

  const handlePasswordChange = (value: string): void => {
    clearValidationErrors(['passwordRequired']);
    setPassword(value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    handleValidation({
      passwordRequired: { message: formValidationMessages.PASSWORD_REQUIRED, invalidIf: !password },
    });

    if (!password) return;

    login(password)
      .then(() => navigate(paths.home))
      .catch(() => setError(INVALID_PASSWORD_ERROR_MESSAGE));
  };

  return (
    <div className={styles.root}>
      <Logo className={styles.logo} />
      <form onSubmit={onSubmit} className={styles.form}>
        <div className={styles.blurBackground} />
        <div className={clsx(styles.content, { [styles.hasError]: areThereAnyValidationErrors })}>
          <Input
            label="Enter password:"
            name="password"
            onChange={(e) => handlePasswordChange(e.target.value)}
            value={password}
            error={validation.passwordRequired}
            ref={inputRef}
          />

          <button
            className={clsx(styles.submitBtn, { [styles.submitBtnDisabled]: loading })}
            type="submit"
            disabled={loading}
          >
            {loading ? <Loader small /> : <Icon icon={icons.reply} size={{ width: 16, height: 12 }} />}
          </button>
        </div>
      </form>

      {error && (
        <div className={styles.toast}>
          <span>{error}</span>
          <Icon icon={icons.close} size={{ width: 13, height: 13 }} onClick={() => setError('')} />
        </div>
      )}
    </div>
  );
}

export default Login;
