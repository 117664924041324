import axios, { AxiosResponse } from 'axios';
import env from 'domain/env';

export function login(password: string): Promise<AxiosResponse> {
  return axios.post(
    `${env.backendUrl}/password`,
    { password },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
}
