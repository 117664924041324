import { ChangeEvent, forwardRef, InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './Input.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  autoCompleteOff?: boolean;
  className?: string;
  error?: string;
  label?: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  value: string;
}

const Input = (
  {
    autoCompleteOff,
    className = '',
    error = '',
    label,
    name,
    onChange,
    placeholder,
    readOnly = false,
    value,
    ...rest
  }: InputProps,
  ref
): JSX.Element => {
  return (
    <div className={clsx(styles.root, className)}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}
      <div className={clsx(styles.inputContainer, { [styles.hasError]: error })}>
        <div>
          <span>&#62;</span>
          <input
            name={name}
            placeholder={placeholder}
            type="password"
            value={value}
            onChange={onChange}
            readOnly={readOnly}
            autoComplete={autoCompleteOff ? 'new-password' : name}
            aria-label={`${name} input`}
            className={clsx(styles.input, { [styles.inputError]: error })}
            ref={ref}
            {...rest}
          />
        </div>
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default forwardRef(Input);
