import { IMAGES } from 'theme/assets/png/images';
import styles from './FilterInformation.module.scss';

const FilterInformation = (): JSX.Element => {
  return (
    <section className={styles.root}>
      <div className={styles.filterContainer}>
        <h3 className={styles.information}>
          ClearAir to przenośny filtr dezaktywujący wirusy, bakterie i grzyby, w tym między innymi SARS-CoV-2.
          <span className={styles.description}>
            {' '}
            Posiada uniwersalny konektor pasujący do większości masek twarzowych na rynku. Filtr wyposażony w baterie
            umożliwiające prace przez 8h oraz wygodny plecak z możliwością noszenia z przodu lub z tyłu.
          </span>
        </h3>

        <h2 className={styles.slogan}>Największa wydajność. Zero szkodliwego ozonu.</h2>

        <div className={styles.wavelength}>
          <img src={IMAGES.wavelength} alt="wavelength range" className="wavelengthRange" />
        </div>

        <p className={styles.deviceDescription}>
          ClearAir to najmniejsze tego typu urządzenie na rynku, które
          <strong> nie emituje szkodliwego dla człowieka ozonu</strong>. Całość oparta jest o
          <strong> światło o długości fali 257 nm które jest najwydajniejsze </strong>w dezaktywacji wirusów, bakterii i
          grzybów nie wydzielając substancji szkodliwych dla człowieka.
        </p>

        <div className={styles.filterConstruction}>
          <img src={IMAGES.toy} alt="toy car" className="toyCar" />

          <p className={styles.filterDescription}>
            Rewolucyjna konstrukcja filtra rewolwerowego wymusza długą drogę przepływu powietrza wymuszając za każdym
            razem inną kompozycję wirusa podczas przelotu przez 7 rurek kwarcowych.
          </p>
        </div>
      </div>
    </section>
  );
};

export default FilterInformation;
