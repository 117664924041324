import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import styles from './Loader.module.scss';

interface LoaderProps {
  className?: string;
  loading?: boolean;
  small?: boolean;
}

const Loader = ({ className = '', loading = true, small = false }: LoaderProps): JSX.Element => {
  return (
    <CSSTransition in={loading} timeout={2000} classNames="loader-transition" unmountOnExit>
      <div className={clsx(styles.root, className)}>
        <span className={clsx(styles.loader, { [styles.small]: small })} />
      </div>
    </CSSTransition>
  );
};

export default Loader;
