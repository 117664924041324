import clsx from 'clsx';
import { IMAGES } from 'theme/assets/png/images';
import styles from './FilterAdvantages.module.scss';
import Advantages from './advantages';

const FilterAdvantages = (): JSX.Element => {
  return (
    <main className={styles.root}>
      <div className={styles.background} />
      <div className={styles.content}>
        <Advantages />

        <section className={styles.advantage}>
          <div className={styles.workDuration}>
            <h2>
              Nawet
              <p className={styles.enlargedText}>8h</p>
              ciągłej pracy
            </h2>

            <p className={styles.batteryCapacityText}>
              dzięki pojemności baterii 12000 mAh. <br />
              Zasilanie poprzez uniwersalny powerbank 12v
            </p>
          </div>
          <img src={IMAGES.toy} alt="car" className={styles.carImage} />
        </section>

        <section className={clsx(styles.respiratorMask)}>
          <h3>Uniwersalny filtr pasuje do każdej maski</h3>
        </section>

        <section className={styles.mask}>
          <p>
            Źródło światła o mocy 9W zapewnia ochrone dla klientow indywidualnych gdzie oddech nie jest podwyższony
            przez wysiłek
          </p>
          <p>
            Obudowa wykonana <br />z lekkiego kompozytu odpornego na uszkodzenia mechaniczne
          </p>
        </section>
      </div>
    </main>
  );
};

export default FilterAdvantages;
