import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Loader from 'theme/loader';
import styles from './Button.module.scss';

type ButtonProps = {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  externalHref?: string;
  icon?: React.ReactNode;
  loading?: boolean;
  onClick?: () => void;
  text?: string;
  to?: string;
  type?: 'button' | 'submit';
  variant?: 'primary' | 'secondary';
};

const Button = ({
  active = false,
  className = '',
  disabled,
  externalHref = '',
  icon,
  loading = false,
  onClick,
  text,
  to = '',
  type = 'button',
  variant = 'primary',
}: ButtonProps): JSX.Element => {
  const classes = {
    className: clsx(styles.root, className, styles[variant], {
      [styles.active]: active,
    }),
  };

  const content = (
    <>
      <Loader loading={loading} className={styles.loader} small />
      {!loading && text}
      {!loading && icon}
    </>
  );

  const linkElement = (
    <Link to={to} {...classes}>
      <span>{text}</span>
      {icon}
    </Link>
  );

  const btnElement = (
    <button {...classes} type={type} onClick={() => onClick?.()} disabled={disabled || loading}>
      {content}
    </button>
  );

  const externalLinkElement = (
    <a {...classes} type={type} href={externalHref} target="_blank" rel="noopener noreferrer">
      {content}
    </a>
  );

  const component = (): JSX.Element => {
    if (to) return linkElement;
    else if (externalHref) return externalLinkElement;
    else return btnElement;
  };

  return component();
};

export default Button;
