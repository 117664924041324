import { FilterTable } from 'domain/types';
import Icon, { icons } from 'theme/icon';
import styles from './TableRow.module.scss';

interface TableRowProps {
  contentTable: FilterTable[];
  title: string;
  value: string;
}

const TableRow = ({ contentTable, title, value }: TableRowProps): JSX.Element => {
  return (
    <tr>
      <td></td>
      <td className="columnWithLimitedWidth">{title}</td>
      {contentTable.map((f, index) => {
        const cellValue = f[value];

        if (typeof cellValue === 'string' || typeof cellValue === 'number') {
          return (
            <td key={index} className={styles.columnWithContent}>
              {cellValue}
            </td>
          );
        } else {
          return (
            <td key={index} className={styles.columnWithContent}>
              <div className={styles.icon}>
                {cellValue ? (
                  <Icon icon={icons.check} size={{ width: 17, height: 13 }} />
                ) : (
                  <Icon icon={icons.close} size={{ width: 15, height: 15 }} />
                )}
              </div>
            </td>
          );
        }
      })}
      <td></td>
    </tr>
  );
};

export default TableRow;
