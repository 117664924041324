// import { useNavigate } from 'react-router-dom';
// import { paths } from 'app/routes/paths.const';
// import Button from 'theme/button';
import Logo from 'theme/logo';
import styles from './Navbar.module.scss';

// interface NavigationButton {
//   label: string;
//   onClick: () => void;
// }

const Navbar = (): JSX.Element => {
  // const navigate = useNavigate();

  // const navigationButtons: NavigationButtonTypes[] = [
  //   {
  //     label: 'Produkty',
  //     onClick: () => {
  //       navigate(paths.home);
  //     },
  //   },
  // {
  //   label: 'O nas',
  //   onClick: () => {
  //     navigate(paths.aboutUs);
  //   },
  // },
  // {
  //   label: 'Kontakt',
  //   onClick: () => {
  //     navigate(paths.contact);
  //   },
  // },
  // ];

  return (
    <nav className={styles.root}>
      <div className={styles.navbar}>
        <Logo size="small" />
        {/* <ul className={styles.navbarButtonsList}>
          {navigationButtons.map(({ label, onClick }, index) => (
            <li key={label + index}>
              <Button text={label} onClick={onClick} className={styles.navbarButton} />
            </li>
          ))}
        </ul> */}
      </div>
    </nav>
  );
};

export default Navbar;
