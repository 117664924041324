import Icon, { icons } from 'theme/icon';
import styles from './Footer.module.scss';

const Footer = (): JSX.Element => {
  return (
    <footer className={styles.root}>
      <div className={styles.footer}>
        <p className={styles.footerSlogan}>Let's talk</p>
        <Icon icon={icons.arrow} size={{ width: 331, height: 123 }} className={styles.arrow} />
      </div>
    </footer>
  );
};

export default Footer;
