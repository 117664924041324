import { FILTERS } from 'domain/const';
import { IMAGES } from 'theme/assets/png/images';
import Button from 'theme/button';
import FilterComparisonTableRow from '../../theme/tableRow/TableRow';
import styles from './FilterComparison.module.scss';

const FilterComparison = (): JSX.Element => {
  return (
    <section className={styles.root}>
      <table className="table">
        <thead>
          <tr>
            <th />
            <th className="columnWithLimitedWidth" />

            {FILTERS.map(({ name, buttonLink }, index) => (
              <th key={name} className="columnWithLimitedWidth">
                <div className={styles.tableHeaderCell}>
                  <h4 className={styles.filterName}>{name}</h4>
                  <img src={IMAGES.toy} alt="car" className={styles.carImage} />
                  <Button text="ORDER NOW" className={styles.orderButton} to={buttonLink} />
                </div>
              </th>
            ))}
            <th>
              <div className={styles.emptyCell} />
            </th>
          </tr>
        </thead>

        <tbody>
          <FilterComparisonTableRow contentTable={FILTERS} title="Rodzaj lampy" value="lampType" />
          <FilterComparisonTableRow contentTable={FILTERS} title="Voltage" value="voltage" />
          <FilterComparisonTableRow contentTable={FILTERS} title="Power UVC" value="powerUVC" />
          <FilterComparisonTableRow contentTable={FILTERS} title="Battery time" value="batteryTime" />

          <FilterComparisonTableRow contentTable={FILTERS} title="Sposób mocowania" value="mountingMethod" />
          <FilterComparisonTableRow contentTable={FILTERS} title="System filtrowania" value="filtrationSystem" />
          <FilterComparisonTableRow contentTable={FILTERS} title="Oddech" value="breath" />
          <FilterComparisonTableRow contentTable={FILTERS} title="Wymiar" value="dimension" />
          <FilterComparisonTableRow contentTable={FILTERS} title="Wydzielanie ozonu" value="ozoneRelease" />
          <FilterComparisonTableRow
            contentTable={FILTERS}
            title="Certyfikat laboratorium"
            value="laboratoryCertificate"
          />
          <FilterComparisonTableRow contentTable={FILTERS} title="Aplikacja" value="app" />
          <FilterComparisonTableRow contentTable={FILTERS} title="Obudowa" value="case" />
          <FilterComparisonTableRow
            contentTable={FILTERS}
            title="Zestaw dodatkowych baterii"
            value="additionalBatteries"
          />
          <FilterComparisonTableRow contentTable={FILTERS} title="Ładowanie" value="charging" />
          <FilterComparisonTableRow contentTable={FILTERS} title="Rodzaj szkła kwarcowego" value="quartzGlassType" />
          <FilterComparisonTableRow contentTable={FILTERS} title="Wymienny akumulator" value="replaceableBattery" />
          <FilterComparisonTableRow
            contentTable={FILTERS}
            title="Wskaźnik ładowania"
            value="batteryChargingIndicator"
          />
          <FilterComparisonTableRow contentTable={FILTERS} title="Gwarancja" value="guarantee" />
          <FilterComparisonTableRow contentTable={FILTERS} title="Cena" value="price" />
        </tbody>
      </table>
    </section>
  );
};

export default FilterComparison;
