import clsx from 'clsx';
import styles from './Hero.module.scss';

interface HeroProps {
  title: string;
  backgroundHeroImage: string;
  backgroundHeroImageAlt?: string;
  imgClassName?: string;
}

const Hero = ({ title, backgroundHeroImage, backgroundHeroImageAlt, imgClassName }: HeroProps): JSX.Element => {
  return (
    <section className={styles.root}>
      <div className={styles.heroContainer}>
        <div className={styles.bgGradient} />
        {backgroundHeroImage && (
          <img src={backgroundHeroImage} alt={backgroundHeroImageAlt} className={clsx(styles.bgImage, imgClassName)} />
        )}
        <h1 className={styles.title}>{title}</h1>
      </div>
    </section>
  );
};

export default Hero;
